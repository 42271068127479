import React from "react"
import SEO from "../components/seo"
import Styles from "../styles/PricingPage.module.scss";
import Header from "../components/Header"
import MediaQuery from "react-responsive";
import GeneralButton from "../components/atoms/GeneralButton"
import { navigate } from "@reach/router"
import { BACK_BUTTON_STR } from "../constant/const";


const headerOption = {
    headerTitle: "料金",
    leftIcon: BACK_BUTTON_STR,
}

const PlanList = [
    {
        planName: "フリー",
        target: "アマチュアスポーツチーム、個人",
        price: 0,
        detailList: [
            {
                name: "アップロード動画の長さ",
                value: "30秒まで"
            },
            {
                name: "アップロード動画の合計",
                value: "40分/月 まで"
            },
            {
                name: "動画のダウンロード",
                value: "不可"
            },
            {
                name: "アプリ広告",
                value: "あり"
            }
        ],
        targetDetail: "個人で動画を整理、比較したい方、あまり動画を撮らないスポーツチーム向け",
        button: {
            label: "無料で始める",
            func: () => navigate( '/registration' )
        }
    },
    {
        planName: "スターター",
        target: "体育会",
        price: 150,
        detailList: [
            {
                name: "アップロード動画の長さ",
                value: "2分まで"
            },
            {
                name: "アップロード動画の合計",
                value: "60分/月 まで"
            },
            {
                name: "動画のダウンロード",
                value: "○"
            },
            {
                name: "アプリ広告",
                value: "なし"
            }
        ],
        targetDetail: "身内で練習動画を効率的に共有、意見交換したいチーム向け",
        button: {
            label: "無料で始める",
            func: () => navigate( '/registration' )
        }
    },
    {
        planName: "ビジネス",
        target: "プロチーム、スポーツクラブ",
        price: 400,
        detailList: [
            {
                name: "アップロード動画の長さ",
                value: "5分まで"
            },
            {
                name: "アップロード動画の合計",
                value: "100分/月 まで"
            },
            {
                name: "動画のダウンロード",
                value: "○"
            },
            {
                name: "アプリ広告",
                value: "なし"
            }
        ],
        targetDetail: "練習日記の代わりとして使う場合や、コーチのオンラインフォローアップが活発なチーム向け",
        button: {
            label: "相談する",
            func: () => navigate( '/contact' )
        }
    },
]

const PricingPage = () =>
    <div>
        <div>
            {/* <MediaQuery query="(min-width: 900px)">
				</MediaQuery> */}
            <MediaQuery query="(max-width: 899px)">
                <Header headerOption={ headerOption } />
            </MediaQuery>
        </div>
        <SEO title="Pricing" />
        <div className={ Styles.container }>
            {/* <h1>料金</h1> */ }
            <div className={ Styles.plan_list_container }>
                { PlanList.map( ( plan, index ) =>
                    <div className={ `${ Styles.plan_container } ${ index === 1 ? Styles.recommended : "" }` }>
                        { index === 1 && <span className={ Styles.recommend_box }>おすすめ</span> }
                        <div className={ Styles.plan_head }>
                            <h2>{ plan.planName }</h2>
                            <p className={ Styles.target }>{ plan.target }向け</p>
                            <div className={ Styles.price }>
                                選手数 ×
                                <span className={ Styles.price_number }>{ plan.price }</span>
                                円{ plan.price !== 0 && "+税" }/月
                            </div>
                            <GeneralButton title={ plan.button.label } className={ Styles.start_button } onClick={ plan.button.func } />
                        </div>
                        <div className={ Styles.plan_detail }>
                            <table>
                                <tbody>
                                    { plan.detailList.map( detail =>
                                        <tr>
                                            <th>{ detail.name }</th>
                                            <td>{ detail.value }</td>
                                        </tr>
                                    ) }
                                </tbody>
                            </table>
                        </div>
                        <div className={ Styles.target_detail_container }>
                            <span className={ Styles.target_box }>
                                { index === 0 ? "おすすめのチーム・個人" : "おすすめのチーム" }
                            </span>
                            <p className={ Styles.target_detail }>{ plan.targetDetail }</p>
                        </div>
                    </div>
                ) }
            </div>
        </div>
    </div>


export default PricingPage
