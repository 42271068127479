import React, { CSSProperties, FC, useEffect } from "react"
import { navigate } from "gatsby"
import Styles from "../styles/Header.module.scss";
import { useMediaQuery } from "react-responsive";
import { BACK_BUTTON_STR } from "../constant/const";
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";
import { LeftArrowIcon } from "../icons/LeftArrowIcon";
import clsx from "clsx"

type BACK_BUTTON_STRType = typeof BACK_BUTTON_STR

export type headerOptionType = {
    headerTitle: string | JSX.Element,
    headerTitleStyle?: CSSProperties,
    leftIcon?: JSX.Element | BACK_BUTTON_STRType,
    rightIcon?: JSX.Element
    hideBorder?: boolean
}
type Props = {
    headerOption: headerOptionType
}

const Header: FC<Props> = ( {
    headerOption: { headerTitle, headerTitleStyle, leftIcon, rightIcon, hideBorder = false }
} ) => {

    const isTabletOrMobile = useMediaQuery( { query: '(max-width: 899px)' } )
    const isLoggedIn = useIsLoggedIn()

    if ( !isLoggedIn ) {
        return (
            <header className={ clsx( Styles.header, hideBorder && Styles.border_hidden_header ) }>
                <div className={ `${ Styles.icon_wrapper } ${ Styles.left_icon_wrapper }` }>
                    <p
                        className={ Styles.to_top }
                        style={ { paddingRight: 4 } }
                        onClick={ () => navigate( "/" ) }>
                        トップページは
                        <br />
                        <span className={ Styles.to_top }>こちら</span>
                    </p>
                </div>
                { typeof headerTitle !== "string" &&
                    <div className={ Styles.container }>
                        <h1 className={ Styles.header_title } style={ headerTitleStyle ? headerTitleStyle : {} }>
                            { headerTitle }
                        </h1>
                    </div> }
                <div className={ `${ Styles.icon_wrapper } ${ Styles.right_icon_wrapper }` }>
                    { rightIcon }
                </div>
            </header>
        )
    }

    if ( !isTabletOrMobile ) {
        return (
            <header className={ Styles.header }>

            </header>
        )
    }

    return (
        <header className={ clsx( Styles.header, hideBorder && Styles.border_hidden_header ) }>
            <div className={ `${ Styles.icon_wrapper } ${ Styles.left_icon_wrapper }` }>
                { leftIcon === BACK_BUTTON_STR ?
                    <LeftArrowIcon
                        style={ { fontSize: 20 } }
                        onClick={ () => window.history.back() } /> :
                    leftIcon }
            </div>
            <div className={ Styles.container }>
                <h1 className={ Styles.header_title } style={ headerTitleStyle ? headerTitleStyle : {} }>
                    { headerTitle }
                </h1>
            </div>
            <div className={ `${ Styles.icon_wrapper } ${ Styles.right_icon_wrapper }` }>
                { rightIcon }
            </div>
        </header>
    )
}
export default Header
